import { css } from "@emotion/react";
import React, { useCallback, useMemo } from "react";

import { useConfigStore } from "../model/config-store";
import { useConfigHasDifference } from "../model/diff/use-config-has-difference";

export function ConfigItemForceSaveSwitch({ configItem, disabled, ...props }) {
  const { forceSaveList, addForceSaveItem, removeForceSaveItem, resetUserValues } =
    useConfigStore();
  const hasDifference = useConfigHasDifference(configItem);

  const isChecked = useMemo(() => {
    return hasDifference || forceSaveList?.includes(configItem?.confId);
  }, [configItem?.confId, forceSaveList, hasDifference]);

  const onChange = useCallback(
    (event) => {
      if (event?.target?.checked) {
        addForceSaveItem(configItem?.confId);
      } else {
        // fixme move to method below
        removeForceSaveItem(configItem?.confId);
        resetUserValues(configItem?.confId);
      }
    },
    [addForceSaveItem, configItem?.confId, removeForceSaveItem, resetUserValues]
  );

  return (
    <input
      className="on-off"
      type="checkbox"
      disabled={disabled}
      checked={isChecked}
      onChange={onChange}
      css={css`
        &&& {
          cursor: ${disabled ? "default" : "pointer"};
        }
      `}
      {...props}
    />
  );
}
