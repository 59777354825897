import React, { useEffect } from "react";
import SimpleBar from "simplebar-react";

import { ReactComponent as SvgClose } from "../../images/close.svg";
import { useAccountsFormStore } from "../../store/AccountsFormStore";

const DeviceInfoModal = ({ closeModal, accId }) => {
  const {
    formValues,
    setFieldValue,
    setFieldChecked,
    getDeviceInfo,
    setDeviceInfo,
  } = useAccountsFormStore();

  useEffect(() => {
    getDeviceInfo(accId).then();
  }, []);

  const onClose = () => {
    useAccountsFormStore.setState({ formValues: null });
    closeModal();
  };

  const onSave = () => {
    setDeviceInfo(accId).then(() => closeModal());
  };

  return (
    <div className="modal-device-info">
      <div className="modal-device-title">
        device info
        <div className="window_close" onClick={onClose}>
          <SvgClose />
        </div>
      </div>
      <SimpleBar style={{ height: "70%" }} forceVisible="y" autoHide={false}>
        <div className="modal-device-options">
          {formValues?.map((item) => (
            <div key={item.name} className="device-options-item">
              <div>{item.title}:</div>
              <div>{item.value}</div>
              <div>
                <input
                  type="text"
                  className="st-in"
                  value={item.inputValue || ""}
                  onChange={(e) => setFieldValue(item.name, e.target.value)}
                />
              </div>
              <div className="list-item-apply">
                <label>
                  <input
                    type="checkbox"
                    className="on-off"
                    checked={item.checked}
                    onChange={(e) => setFieldChecked(item.name, !item.checked)}
                  />
                </label>
              </div>
            </div>
          ))}
        </div>
      </SimpleBar>

      <div className="modal-device-btn">
        <div onClick={onSave} className="p-btn grayBg">
          save
        </div>
      </div>
    </div>
  );
};

export default DeviceInfoModal;
