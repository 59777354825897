import { useMemo } from "react";

import { useCurrentPartnerRooms } from "../../partner/model/use-current-partner-rooms";

export function usePriceAddTotal({
  selectedRoom,
  selectedRoomType,
  selectedBotMode,
  config,
  userValues,
} = {}) {
  const allowedRooms = useCurrentPartnerRooms();
  return useMemo(() => {
    const basePriceAdd =
      allowedRooms?.data?.[selectedRoom]?.[selectedRoomType]?.[selectedBotMode]
        ?.priceAdd || 0;
    return [...(config?.data?.[0] || []), ...(config?.data?.[1] || [])]
      ?.filter((item) => !!item?.priceAdd)
      ?.filter((item) => userValues?.[item?.confId] === item?.activeValue)
      ?.reduce((acc, item) => acc + (item?.priceAdd || 0), basePriceAdd);
  }, [
    allowedRooms?.data,
    config?.data,
    selectedBotMode,
    selectedRoom,
    selectedRoomType,
    userValues,
  ]);
}
