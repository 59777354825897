import { css } from "@emotion/react";
import { useQueryClient } from "@tanstack/react-query";
import { diff } from "deep-object-diff";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";

import Select from "../../../components/Select";
import { useEventBus } from "../../../features/event-bus/use-event-bus";
import { ReactComponent as SvgClose } from "../../../images/close.svg";
import { useAccountsStore } from "../../../store/AccountsStore";
import { useCurrentUser } from "../../user/use-current-user";
import { useConfigStore } from "../model/config-store";
import { useConfigHasDifference } from "../model/diff/use-config-has-difference";
import { getBotMode } from "../model/filters/get-bot-mode";
import { useConfigFilters } from "../model/filters/use-config-filters";
import { useConfig, useConfigQueryKey } from "../model/use-config";
import { useConfigSave } from "../model/use-config-save";
import { usePriceAddTotal } from "../model/use-price-add-total";
import { ConfigModalResetButton } from "./config-modal-reset-button";
import { ConfigModalSaveButton } from "./config-modal-save-button";
import s from "./config-modal.scss";
import { DynamicInputList } from "./dynamic-input-list";

export const ConfigModal = ({ closeModal }) => {
  const { t } = useTranslation("config");
  const simpleBarRef = useRef(null);

  const accountStore = useAccountsStore();
  const currentUser = useCurrentUser();

  const {
    isFetching: isConfigFiltersFetching,
    applyFilters,
    selectedRoom,
    selectedRoomType,
    selectedBotMode,
    roomSelectOptions,
    roomTypeSelectOptions,
    botModeSelectOptions,
  } = useConfigFilters();

  const configs = useConfig({
    accIds: accountStore?.selectedAccounts,
    roomName: selectedRoom,
    gameType: selectedRoomType,
    mode: selectedBotMode,
  });

  const [isConfigSaving, setIsConfigSaving] = useState(false);
  const isLoading = configs?.isFetching || isConfigSaving;
  const configHasDifference = useConfigHasDifference();

  const { config, setConfig, resetUserValues, userValues, forceSaveList, resetAll } =
    useConfigStore();

  const priceAddTotal = usePriceAddTotal({
    config: configs,
    userValues,
    selectedRoom,
    selectedRoomType,
    selectedBotMode,
  });

  // -------------------------------------------------------------------------- ERR
  const [errors, setErrors] = useState([]);
  const getError = useCallback(
    ({ fieldName }) => {
      return errors?.find((i) => i?.fieldName === fieldName);
    },
    [errors]
  );
  const setError = useCallback(({ fieldName, message, elementRef }) => {
    setErrors((current) => {
      return [...current, { fieldName, message, elementRef }];
    });
  }, []);
  const removeError = useCallback(({ fieldName }) => {
    setErrors((current) => {
      return current?.filter((i) => i?.fieldName !== fieldName);
    });
  }, []);
  const scrollToFirstError = useCallback(() => {
    try {
      errors?.[0]?.elementRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } catch (error) {
      //
    }
  }, [errors]);
  const canSave = useMemo(() => {
    return !errors?.length;
  }, [errors?.length]);
  // -------------------------------------------------------------------------- ERR

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleTabSelect = useCallback(
    (index) => {
      if (!canSave) {
        scrollToFirstError();
        return false;
      }
      setSelectedTabIndex(index);
      setErrors(() => []);
    },
    [canSave, scrollToFirstError]
  );
  useEffect(() => {
    if (simpleBarRef?.current) {
      setTimeout(() => {
        simpleBarRef.current.getScrollElement().scrollTop = 0;
      }, 0);
    }
  }, [config, selectedTabIndex]);

  const queryClient = useQueryClient();
  const configSave = useConfigSave();

  useEffect(() => {
    if (configs?.data) {
      setConfig(configs?.data);
      resetUserValues();
    }
  }, [configs?.data, resetUserValues, setConfig]);

  const save = useCallback(async () => {
    if (!canSave) {
      scrollToFirstError();
      return;
    }
    setIsConfigSaving(true);
    const result = await configSave({
      accIds: accountStore.selectedAccounts,
      partId: currentUser?.partId,
      selectedBotMode,
    });
    setIsConfigSaving(false);
    if (result?.data?.status === "SUCCESS") {
      toast?.success(`${result?.data?.status}`, {
        delay: 0,
        autoClose: 2000,
      });
      closeModal();
      // ! data may NOT change and will not be reset by effect, do explicitly
      resetUserValues();
      queryClient.invalidateQueries({ queryKey: [useConfigQueryKey] });
    }
    if (result?.data?.status === "ERROR") {
      toast?.error(`${result?.data?.status} : ${result?.data?.code}`, {
        delay: 0,
        autoClose: 10000,
      });
    }
  }, [
    accountStore.selectedAccounts,
    canSave,
    closeModal,
    configSave,
    currentUser?.partId,
    queryClient,
    resetUserValues,
    scrollToFirstError,
    selectedBotMode,
  ]);

  useEffect(
    () => {
      return () => {
        queryClient.removeQueries({
          queryKey: [useConfigQueryKey],
        });
        resetAll();
      };
    },
    //eslint-disable-next-line
    []
  );

  useEventBus(["CONFIG_CHANGED"], (message: any) => {
    if (
      accountStore.selectedAccounts?.some((account) =>
        message?.accIds?.includes(account)
      )
    ) {
      console.log(
        "xxxxxxxxxxxxx ===================== CONFIG_CHANGED ",
        accountStore.selectedAccounts,
        message,
        {
          selectedRoom: message?.lastRoom,
          selectedRoomType: message?.lastGameType,
          selectedBotMode: getBotMode(message?.lastWorkingMode),
        },
        {
          selectedRoom,
          selectedRoomType,
          selectedBotMode,
        },
        diff(
          {
            selectedRoom: message?.lastRoom,
            selectedRoomType: message?.lastGameType,
            selectedBotMode: getBotMode(message?.lastWorkingMode),
          },
          {
            selectedRoom,
            selectedRoomType,
            selectedBotMode,
          }
        )
      );
      if (
        !Object.keys(
          diff(
            {
              selectedRoom: message?.lastRoom,
              selectedRoomType: message?.lastGameType,
              selectedBotMode: getBotMode(message?.lastWorkingMode),
            },
            {
              selectedRoom,
              selectedRoomType,
              selectedBotMode,
            }
          ) || {}
        )?.length
      ) {
        queryClient?.invalidateQueries({
          queryKey: [useConfigQueryKey],
          refetchType: "active",
        });
      }
    }
  });

  return (
    <Tabs onSelect={handleTabSelect}>
      <div
        css={css`
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          width: 600px;
          padding: 30px 50px;
        `}
      >
        <div
          css={css`
            color: var(--text-color);
            font-family: Montserrat;
            font-size: 24px;
            font-weight: 500;
            height: 30px;
            line-height: 30px;
            text-align: center;
            text-transform: uppercase;
          `}
        >
          {t("accountConfiguration")}
          <div className="window_close" onClick={closeModal}>
            <SvgClose />
          </div>
        </div>
        {isConfigFiltersFetching && (
          <div
            className="loading"
            css={css`
              &&& {
                align-self: center;
                :after {
                  height: 64px;
                  width: 64px;
                }
              }
            `}
          />
        )}
        {!isConfigFiltersFetching && (
          <>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 15px;
              `}
            >
              <div className={s.selectRoom}>
                <Select
                  placeHolder="SELECT ROOM"
                  disabled={isLoading || !roomSelectOptions?.length}
                  totalPrice={priceAddTotal}
                  options={roomSelectOptions}
                  selected={roomSelectOptions?.find(
                    (option) => option.value === selectedRoom
                  )}
                  onSelect={(option) => {
                    setErrors(() => []);
                    applyFilters({
                      selectedRoom: option?.value,
                    });
                  }}
                />
              </div>
              <div className={s.selectsGroup}>
                <div className={s.select}>
                  <Select
                    placeHolder="SELECT TYPE"
                    disabled={isLoading || !roomTypeSelectOptions?.length}
                    options={roomTypeSelectOptions}
                    selected={roomTypeSelectOptions?.find(
                      (option) => option.value === selectedRoomType
                    )}
                    onSelect={(option) => {
                      applyFilters({
                        selectedRoomType: option?.value,
                      });
                      setErrors(() => []);
                    }}
                  />
                </div>
                <div className={s.select}>
                  <Select
                    placeHolder="SELECT MODE"
                    disabled={isLoading || !botModeSelectOptions?.length}
                    options={botModeSelectOptions}
                    selected={botModeSelectOptions?.find(
                      (option) => option.value === selectedBotMode
                    )}
                    onSelect={(option) => {
                      applyFilters({
                        selectedBotMode: option?.value,
                      });
                      setErrors(() => []);
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {!isConfigFiltersFetching && (
          <>
            <TabList className={s.tabs}>
              {config?.[0] && (
                <Tab
                  className={`${s.tab} tabControl`}
                  selectedClassName={s.activeTab}
                  style={{
                    ...(isLoading && {
                      opacity: 0.5,
                    }),
                    minHeight: "30px",
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border:
                      selectedTabIndex === 0 && errors?.length
                        ? "1px solid red"
                        : "transparent",
                  }}
                >
                  {t("basic")}
                </Tab>
              )}
              {config?.[1] && (
                <Tab
                  className={`${s.tab} tabControl`}
                  selectedClassName={s.activeTab}
                  style={{
                    ...(isLoading && {
                      opacity: 0.5,
                    }),
                    minHeight: "30px",
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    border:
                      selectedTabIndex === 1 && errors?.length
                        ? "1px solid red"
                        : "transparent",
                  }}
                >
                  {t("pro")}
                </Tab>
              )}
            </TabList>
            <div className="m-settings-content">
              <SimpleBar
                className={s.simpleBar}
                ref={simpleBarRef}
                forceVisible="y"
                autoHide={false}
              >
                {config?.[0] && (
                  <TabPanel>
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        padding: 0 1rem;
                      `}
                    >
                      {
                        <DynamicInputList
                          isLoading={isLoading}
                          config={config[0]}
                          getError={getError}
                          setError={setError}
                          removeError={removeError}
                        />
                      }
                    </div>
                  </TabPanel>
                )}
                {config?.[1] && (
                  <TabPanel>
                    <div
                      css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        padding: 0 1rem;
                      `}
                    >
                      {
                        <DynamicInputList
                          isLoading={isLoading}
                          config={config[1]}
                          getError={getError}
                          setError={setError}
                          removeError={removeError}
                        />
                      }
                    </div>
                  </TabPanel>
                )}
              </SimpleBar>
            </div>
            <div className={s.controlButtons}>
              <ConfigModalResetButton
                disabled={
                  isLoading ||
                  !Object.keys(config)?.length ||
                  (!configHasDifference && !forceSaveList?.length)
                }
                onClick={() => resetUserValues()}
              />
              <ConfigModalSaveButton
                disabled={
                  isLoading ||
                  !Object.keys(config)?.length ||
                  (!configHasDifference && !forceSaveList?.length)
                }
                onClick={save}
              />
            </div>
          </>
        )}
      </div>
    </Tabs>
  );
};
