import { create } from "zustand";

import { api } from "../shared/constants";

export const useDepositStore = create((set, get) => ({
  initialData: {},
  gifts: [],
  invoice: null,
  usdtToFuelRatio: 1,
  initialRequest: async () => {
    try {
      const response = await api.initialReq({});
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        set({ gifts: parsedData.gifts, usdtToFuelRatio: parsedData.fuel2UsdPrice });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  createInvoice: async (amount) => {
    try {
      const response = await api.createInvoiceReq({
        amount: amount,
        ticker: "trc20/usdt",
      });
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        set({ invoice: parsedData });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  manualCallback: async (invId) => {
    try {
      const response = await api.manualCallbackReq({ invId: invId });
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        return parsedData;
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
}));
