import { css } from "@emotion/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { DynamicInput } from "../../../shared/ui/dynamic-input/dynamic-input";
import { ConfigItemForceSaveSwitch } from "./config-item-force-save-switch";
// fixme
import s from "./config-modal.scss";

function isParent(configItem, config) {
  return config.some((item) => item.parentConfId === configItem.confId);
}

export const DynamicInputList = ({
  isLoading = false,
  config,
  getError,
  setError,
  removeError,
}) => {
  const [disabledStates, setDisabledStates] = useState({});
  const { t } = useTranslation("config");
  const { t: d } = useTranslation("descr");

  const handleDisabledChange = useCallback((confId, isDisabled) => {
    setDisabledStates((prevStates) => ({
      ...prevStates,
      [confId]: isDisabled,
    }));
  }, []);

  return (
    <>
      {config?.map((configItem) => {
        return (
          <div
            key={configItem?.confId}
            className={`${
              isParent(configItem, config) || !configItem?.parentConfId
                ? s.itemContainerParent
                : s.itemContainer
            } ${disabledStates[configItem.confId] ? s.disabled : ""}`}
            css={css`
              &&& {
                min-height: 30px;
                border-radius: 16px !important;
                background-color: ${isParent(configItem, config)
                  ? "rgba(125, 67, 201, 0.03)"
                  : "transparent"};
                padding-top: ${isParent(configItem, config) ? 0 : 0}rem;
                padding-bottom: ${isParent(configItem, config) ? 0 : 0}rem;
                padding-left: ${isParent(configItem, config) ? 0.5 : 0.5}rem;
                padding-right: ${isParent(configItem, config) ? 0.2 : 0.2}rem;
              }
            `}
          >
            <div
              css={css`
                &&& {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  text-align: left;
                }
              `}
            >
              <span
                css={css`
                  &&& {
                    font-size: 11px;
                    text-transform: uppercase;
                    font-weight: ${isParent(configItem, config) ? 700 : 500};
                    color: var(--text-color);
                  }
                `}
              >
                {t(configItem?.name)}
              </span>
              <span
                css={css`
                  &&& {
                    font-size: 9px;
                    text-align: left;
                    max-width: 80%;
                    color: var(--text-color);
                  }
                `}
              >
                {configItem?.descr !== "" && d(configItem?.name)}
              </span>
            </div>
            {/* if group input is null*/}
            {/* give grid cell a content to push reset switch to the right */}
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              <DynamicInput
                isDisabled={isLoading}
                configItem={configItem}
                config={config}
                getError={getError}
                setError={setError}
                removeError={removeError}
                onDisabledChange={handleDisabledChange}
              />
            </div>
            {!configItem?.parentConfId && (
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <ConfigItemForceSaveSwitch configItem={configItem} />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
