import { create } from "zustand";

import { api } from "../shared/constants";

export const useAccountsFormStore = create((set, get) => ({
  formValues: null,
  setFieldValue: (name, value) =>
    set((state) => ({
      formValues: state.formValues.map((item) =>
        item.name === name ? { ...item, inputValue: value } : item
      ),
    })),
  setFieldChecked: (name, value) =>
    set((state) => ({
      formValues: state.formValues.map((item) =>
        item.name === name ? { ...item, checked: value } : item
      ),
    })),
  getDeviceInfo: async (accId) => {
    try {
      const response = await api.getDeviceInfoReq({ accId: accId });
      const parsedData = response.data;
      set({
        formValues: parsedData.deviceOptions.map((item) => ({
          ...item,
          inputValue: "",
          checked: false,
        })),
      });
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  setDeviceInfo: async (accId) => {
    const deviceOptions = get().formValues.map((item) => ({
      name: item.name,
      title: item.title,
      value: item.checked ? item.inputValue : item.value,
    }));
    try {
      const response = await api.setDeviceInfoReq({
        accId: accId,
        deviceOptions: deviceOptions,
      });
      const parsedData = response.data;
    } catch (error) {
      console.error("Error: ", error);
    }
  },
}));
