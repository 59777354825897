// sort-imports-ignore
import "./App.scss";
import "./simplebar.css";
import "react-toastify/dist/ReactToastify.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import { appRouter } from "./app/app-router";
import { ApiContext } from "./context/context";
import { useSessionStore } from "./entities/user/model/session-store";
import { api } from "./shared/constants";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  const darkModeCookie = Cookies.get("isDarkMode");
  const { theme } = useSessionStore();
  useEffect(() => {
    if (darkModeCookie === "1") {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkModeCookie]);
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ApiContext.Provider value={api}>
        <div className="App">
          <RouterProvider router={appRouter} />
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={theme}
          transition={Slide}
          style={{ zIndex: 99999, width: "480px" }}
        />
      </ApiContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
//deploy
