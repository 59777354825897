import React, { useMemo } from "react";

import { useSessionStore } from "../../../entities/user/model/session-store";
import { useSessionsDefaultFiltersOptions } from "../../../entities/user/model/use-sessions-default-filters-options";
import { FilterSelect } from "../../../shared/ui/filter-select";
import { formatNumberWithSpaces } from "../../../shared/utils/utils";
import { useSessionPageStore } from "../../../store/session-page-store";

export function SessionsPartId({ ...props }) {
  const subPartners = useSessionStore((session) => session?.subPartners);
  const defaults = useSessionsDefaultFiltersOptions();
  const { searchParams, setSearchParam, setSearchParams } = useSessionPageStore();

  const options = useMemo(() => {
    return [
      ...([defaults?.partIds?.option] || []),
      ...(subPartners || []).map((partner) => ({
        value: partner?.partId,
        label: formatNumberWithSpaces(partner?.name),
      })),
    ];
  }, [defaults?.partIds?.option, subPartners]);

  return (
    //fixme reset roomType on select?
    <FilterSelect
      searchParams={searchParams}
      setSearchParam={setSearchParam}
      setSearchParams={setSearchParams}
      resetOthers
      options={options}
      {...props}
    />
  );
}
