import { useMemo } from "react";

export function getBotModes(rooms, selectedRoom, selectedRoomType) {
  return Object.keys(rooms?.[selectedRoom]?.[selectedRoomType] ?? {});
}

export function useBotModeSelectOptions(rooms, selectedRoom, selectedRoomType) {
  return useMemo(() => {
    return (
      rooms &&
      selectedRoom &&
      selectedRoomType &&
      Object.entries(rooms?.[selectedRoom]?.[selectedRoomType] || {})
        ?.map((item) => {
          const botMode = item?.[1];
          return {
            value: item?.[0],
            label: botMode?.name || "",
            hint: botMode?.priceAdd > 0 ? botMode?.priceAdd : null,
          };
        })
        .filter(Boolean)
    );
  }, [rooms, selectedRoomType, selectedRoom]);
}
