import { useEffect } from "react";
import useWebSocket, { resetGlobalState } from "react-use-websocket";

const BASE_WS_URL = process.env.REACT_APP_BASE_WS_URL || "wss://user.poker-eye.com";

export function useEventBus(
  eventIds = [],
  callBack,
  { enabled = true, onClose, ...props } = {}
) {
  const webSocket = useWebSocket(`${BASE_WS_URL}/api/ws-events-emitter`, {
    share: true,
    retryOnError: true,
    //Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
    onOpen: (event) => {
      console.log(
        "xxxxxxxxxxxxxxxxx socket opened ",
        `${BASE_WS_URL}/api/ws-events-emitter`,
        event
      );
    },
    onClose: (event) => {
      console.log(
        "xxxxxxxxxxxxxxxxx socket closed ",
        `${BASE_WS_URL}/api/ws-events-emitter`,
        event
      );
      if (typeof onClose === "function") {
        onClose(event);
      }
    },
    onError: (event) => console.log("xxxxxxxxxxxxxxxxx socket error ", event),
    onMessage: (event) => {
      if (enabled) {
        try {
          const message = JSON.parse(event.data);
          if (eventIds?.includes(message?.event)) {
            if (typeof callBack === "function") {
              callBack(message);
            }
          }
        } catch (err) {
          //
        }
      }
    },
    ...props,
  });
  return webSocket;
}

export function useResetGlobalState() {
  useEffect(() => {
    resetGlobalState(`/api/ws-events-emitter`);
  }, []);
}
