import { css } from "@emotion/react";
import React from "react";
import { useTranslation } from "react-i18next";

export function ConfigModalResetButton({ ...props }) {
  const { t } = useTranslation("config");
  return (
    <button
      className="resetButton"
      css={css`
        &&& {
          opacity: 1;
          cursor: pointer;
          :disabled {
            opacity: 0.5;
            //cursor: no-drop;
          }
        }
      `}
      {...props}
    >
      {t("reset")}
    </button>
  );
}
