import { useMemo } from "react";

export function getRoomTypes(rooms, selectedRoom) {
  return Object.keys(rooms?.[selectedRoom] || {}).filter((item) => item !== "icon");
}

export function useRoomTypeSelectOptions(rooms, selectedRoom) {
  return useMemo(() => {
    return (
      rooms &&
      selectedRoom &&
      getRoomTypes(rooms, selectedRoom).map((item) => ({
        value: item,
        label: item,
      }))
    );
  }, [rooms, selectedRoom]);
}
