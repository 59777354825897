import { useTranslation } from "react-i18next";

import { useEventBus } from "../../features/event-bus/use-event-bus";
import { useSessionStore } from "./model/session-store";
import { useUserLogOut } from "./use-user-log-out";

export function useUserEventBusEvents() {
  // fixme remove header binding
  const { t } = useTranslation("header");
  const session = useSessionStore();
  const userLogOut = useUserLogOut();
  useEventBus(["PARTNER_KEY_CHANGED", "DELETE"], async (message) => {
    if (message?.event === "DELETE") {
      if (
        message?.partId ||
        (session?.accId && Number(session?.accId) === message?.accId)
      ) {
        await userLogOut({ message: `${t("DELETE_PARTID_ACCID")}.` });
      }
    }
    // https://asiateam.youtrack.cloud/issue/PEYETESTS-195/Izmenit-reakciyu-na-sobytie-vebsoketa-PARTNERKEYCHANGED
    // if (message?.event === "PARTNER_KEY_CHANGED") {
    //   await userLogOut({ message: `${t("PARTNER_KEY_CHANGED")}.` });
    // }
  });
}
