import { useEffect } from "react";

import { appRouter } from "../../app/app-router";
import { apiInstance } from "../../shared/constants";
import { useSessionStore } from "./model/session-store";

export function useUserAuthAxiosInterceptor() {
  const { resetUserData } = useSessionStore();
  return useEffect(() => {
    console.log("xxxxxxxxxxxxxxxxxxx install interceptor");
    const authInterceptor = apiInstance?.interceptors?.response?.use(
      (response) => {
        if (
          response?.data?.code?.toUpperCase() === "Unauthorized"?.toUpperCase() &&
          response?.data?.status?.toUpperCase() === "ERROR"?.toUpperCase()
        ) {
          resetUserData();
          appRouter.navigate("/login", {
            replace: true,
          });
          return Promise.reject(response);
        }
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    return () => {
      apiInstance?.interceptors?.response.eject(authInterceptor);
    };
  }, [resetUserData]);
}
