import { useQuery } from "@tanstack/react-query";

import { api } from "../../../shared/constants";

export const useConfigQueryKey = `/api/accounts/cfgReq`;

export function useConfig({ accIds, roomName, gameType, mode } = {}, queryOptions) {
  return useQuery({
    enabled: !!(accIds && roomName && gameType && mode),
    queryKey: [useConfigQueryKey, { accIds, roomName, gameType, mode }],
    queryFn: () => {
      return api
        .cfgReq({ accIds, roomName, gameType, mode })
        ?.then((res) => res?.data?.cfgs);
    },
    ...queryOptions,
  });
}
