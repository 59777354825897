import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { getUrlParamsObject } from "./get-url-params-object";

export function useRouteUtils() {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamsObject = useMemo(() => {
    return getUrlParamsObject(searchParams);
  }, [searchParams]);

  const setSearchParam = useCallback(
    (key, value, timeOut = 5) => {
      setTimeout(() => {
        if (searchParams && typeof key === "string") {
          if (!value) {
            setSearchParams((searchParams) => {
              searchParams?.delete(key);
              return searchParams;
            });
            return;
          }
          setSearchParams((searchParams) => {
            searchParams?.set(key, value);
            return searchParams;
          });
        }
      }, timeOut);
    },
    [searchParams, setSearchParams]
  );

  const removeSearchParams = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  return useMemo(
    () => ({
      searchParams,
      searchParamsObject,
      setSearchParams,
      setSearchParam,
      removeSearchParams,
    }),
    [
      removeSearchParams,
      searchParams,
      searchParamsObject,
      setSearchParam,
      setSearchParams,
    ]
  );
}
