import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import accountsTranslations from "./shared/translations/accounts";
import configTranslation from "./shared/translations/config";
import configDescriptionTranslation from "./shared/translations/configDescr";
import deviceInfoTranslations from "./shared/translations/deviceInfo";
import guideTranslations from "./shared/translations/guide";
import headerTranslations from "./shared/translations/header";
import partnersTranslations from "./shared/translations/partners";
import sessionsTranslations from "./shared/translations/sessions";

i18n
  .use(LanguageDetector) // добавьте это перед initReactI18next
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        accounts: accountsTranslations.en,
        sessions: sessionsTranslations.en,
        partners: partnersTranslations.en,
        header: headerTranslations.en,
        deviceInfo: deviceInfoTranslations.en,
        guide: guideTranslations.en,
        config: configTranslation.en,
        descr: configDescriptionTranslation.en,
      },
      zh: {
        accounts: accountsTranslations.zh,
        sessions: sessionsTranslations.zh,
        partners: partnersTranslations.zh,
        header: headerTranslations.zh,
        deviceInfo: deviceInfoTranslations.zh,
        guide: guideTranslations.zh,
        config: configTranslation.zh,
        descr: configDescriptionTranslation.zh,
      },
    },
    fallbackLng: "en",
    detection: {
      order: ["cookie"], // вы можете добавить другие способы обнаружения языка
      caches: ["cookie"], // где сохранять выбранный язык
      cookie: "i18next", // название куки
    },
    interpolation: {
      escapeValue: false,
    },
    ns: ["accounts", "sessions"],
  });

export default i18n;
