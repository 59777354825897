import { useAtom } from "jotai/index";
import { useCallback } from "react";

import { useCurrentPartnerRooms } from "../../../partner/model/use-current-partner-rooms";
import { configFiltersAtom } from "./config-filters-atom";
import { firstBotMode } from "./first-bot-mode";
import { firstRoomType } from "./first-room-type";
import { getBotModes } from "./get-bot-modes";
import { getRoomTypes } from "./get-room-types";

export function useConfigApplyFilters() {
  const [currentFilters, setCurrentFilters] = useAtom(configFiltersAtom);
  const allowedRooms = useCurrentPartnerRooms();
  return useCallback(
    ({
      selectedRoom,
      selectedRoomType,
      selectedBotMode,
    }: {
      selectedRoom?: string,
      selectedRoomType?: string,
      selectedBotMode?: string,
    } = {}) => {
      const newRoom = selectedRoom || currentFilters?.selectedRoom;

      const newRoomType =
        selectedRoomType ||
        (getRoomTypes(allowedRooms?.data, newRoom)?.includes(
          currentFilters?.selectedRoomType
        )
          ? currentFilters?.selectedRoomType
          : firstRoomType(allowedRooms?.data, newRoom));

      const newBotMode =
        selectedBotMode ||
        (getBotModes(allowedRooms?.data, newRoom, newRoomType)?.includes(
          currentFilters?.selectedBotMode
        )
          ? currentFilters?.selectedBotMode
          : firstBotMode(allowedRooms?.data, newRoom, newRoomType));

      setCurrentFilters({
        selectedRoom: newRoom,
        selectedRoomType: newRoomType,
        selectedBotMode: newBotMode,
      });
    },
    [
      allowedRooms?.data,
      currentFilters?.selectedBotMode,
      currentFilters?.selectedRoom,
      currentFilters?.selectedRoomType,
      setCurrentFilters,
    ]
  );
}
