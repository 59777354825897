import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { toast } from "react-toastify";

import DeleteModal from "../../components/modal/DeleteModal";
import InoutModal from "../../components/modal/InoutModal";
import LogModal from "../../components/modal/LogModal";
import ResetModal from "../../components/modal/ResetModal";
import TopupModal from "../../components/modal/TopupModal";
import { useSessionStore } from "../../entities/user/model/session-store";
import { ReactComponent as SvgClose } from "../../images/close.svg";
import { ReactComponent as SvgHistory } from "../../images/history.svg";
import { formatNumberWithSpaces } from "../../shared/utils/utils";
import { usePartnersStore } from "../../store/PartnersStore";

export const PartnersTableRow = ({
  index,
  authKey,
  partnerId,
  partnerName,
  notes,
  accs,
  fuelBilled,
  hands,
  win,
  rake,
  insur,
  errors,
  fuelBalance,
}) => {
  const { t } = useTranslation("partners");
  const [selectedModal, setSelectedModal] = useState(null);
  const [partnerAuthKey, setPartnerAuthKey] = useState(authKey || "");
  const partnersStore = usePartnersStore();
  const sessionStore = useSessionStore();

  const [note, setNote] = useState(notes || "");
  const [editingNote, setEditingNote] = useState(false);

  const openModal = (modalName) => {
    setSelectedModal(modalName);
    document.body.classList.add("ReactModal__Body--open");
  };

  const closeModal = () => {
    setSelectedModal(null);
    document.body.classList.remove("ReactModal__Body--open");
  };

  const updateNote = () => {
    partnersStore.updateNote(partnerId, note).then(() => setEditingNote(false));
  };

  const copyCodeHandle = () => {
    navigator.clipboard
      .writeText(partnerAuthKey)
      .then(() => {
        toast.success("Code copied successfully!");
      })
      .catch((error) => {
        console.error("Ошибка копирования в буфер обмена:", error);
        toast.error("Failed to copy code.");
      });
  };

  const handleGenerateAuthKey = () => {
    partnersStore
      .generateAuthKey(partnerId)
      .then((res) => {
        const newAuthKey = res.newAuthkey;
        setPartnerAuthKey(newAuthKey);
        setSelectedModal("options");

        navigator.clipboard
          .writeText(newAuthKey)
          .then(() => {
            toast.success("Code reset successfully and copied to clipboard!");
          })
          .catch((clipboardError) => {
            console.error("Ошибка копирования в буфер обмена:", clipboardError);
            toast.success(
              "Code reset successfully! But failed to copy to clipboard."
            );
          });
      })
      .catch((error) => {
        console.error("Ошибка сброса кода:", error);
        toast.error("Failed to reset code.");
      });
  };

  const handleDelete = () => {
    partnersStore.deletePartner(partnerId).then((parsedData) => {
      if (parsedData?.status === "SUCCESS") {
        partnersStore.fetchPartners({ role: sessionStore.role });
        sessionStore.getSubpartnersReq();
      } else {
        if (parsedData.status === "ERROR") {
          toast.error(t(parsedData?.code), {
            delay: 0,
            autoClose: 10000,
            position: "top-center",
          });
        }
      }
    });
  };

  return (
    <>
      <div className="table-tr" key={partnerId}>
        <div>{index + 1}</div>
        <div>
          <span className="partner-options" onClick={() => openModal("options")}>
            {formatNumberWithSpaces(partnerName)}
          </span>
        </div>
        <div className="editable-note">
          {editingNote ? (
            <input
              type="text"
              value={note}
              onChange={(e) => {
                setNote(e.target.value);
              }}
              onBlur={updateNote}
              onKeyDown={(event) => {
                if (event?.key === "Enter") {
                  updateNote();
                }
              }}
              maxLength={8}
              autoFocus
            />
          ) : (
            <span onClick={() => setEditingNote(true)}>{note}</span>
          )}
        </div>
        {sessionStore.role === "AGENT" && <div>{accs}</div>}
        <div>{fuelBilled?.toLocaleString()}</div>
        <div>{hands?.toLocaleString()}</div>
        <div>{win?.toLocaleString()}</div>
        <div>{rake?.toLocaleString()}</div>
        <div>{insur?.toLocaleString()}</div>
        <div>{errors}</div>
        <div>{fuelBalance?.toLocaleString()}</div>
        <div>
          <div className="fuel-btns">
            <div className="fuel-btn in-out-btn" onClick={() => openModal("inout")}>
              {t("inOut")}
            </div>
            <div className="fuel-btn top-up-btn" onClick={() => openModal("topup")}>
              {t("topUp")}
            </div>
          </div>
        </div>
        <div className="partner-history">
          <div className="p-btn" onClick={() => openModal("log")}>
            <SvgHistory />
          </div>
        </div>
      </div>
      <Modal
        isOpen={selectedModal === "options"}
        onRequestClose={closeModal}
        closeTimeoutMS={
          selectedModal === "delete" || selectedModal === "reset" ? 0 : 300
        }
        className={`modal ${selectedModal === "options" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <div className="modal-inner-mini">
          <div className="window_close" onClick={closeModal}>
            <SvgClose />
          </div>
          <div className="modal-title">Options</div>
          <div className="modal-inner-mini-buttons">
            <div onClick={copyCodeHandle} className="p-btn">
              Copy Code
            </div>
            <div className="p-btn" onClick={() => openModal("reset")}>
              Reset Code
            </div>
            <div className="p-btn extra" onClick={() => openModal("delete")}>
              Delete
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={selectedModal === "inout"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "inout" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <InoutModal
          closeModal={closeModal}
          selectedPartnerName={partnerName}
          selectedPartId={partnerId}
          partnerBalance={fuelBalance}
        />
      </Modal>
      <Modal
        isOpen={selectedModal === "topup"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "topup" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <TopupModal
          closeModal={closeModal}
          selectedPartnerName={partnerName}
          selectedPartId={partnerId}
          partnerBalance={fuelBalance}
        />
      </Modal>
      <Modal
        isOpen={selectedModal === "log"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "log" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <LogModal selectedPartId={partnerId} closeModal={closeModal} />
      </Modal>
      <Modal
        isOpen={selectedModal === "delete"}
        onRequestClose={closeModal}
        // closeTimeoutMS={300}
        className={`modal ${selectedModal === "settings" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <DeleteModal handleDelete={handleDelete} closeModal={closeModal} />
      </Modal>

      <Modal
        isOpen={selectedModal === "reset"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        className={`modal ${selectedModal === "settings" ? "closing-effect" : ""}`}
        overlayClassName="modal-overlay"
      >
        <ResetModal onReset={handleGenerateAuthKey} closeModal={closeModal} />
      </Modal>
    </>
  );
};
