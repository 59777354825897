import React, { useCallback, useState } from "react";

import { useSessionStore } from "../../entities/user/model/session-store";
import { ReactComponent as SvgClose } from "../../images/close.svg";
import { parseTelegramUsername } from "../../shared/utils/utils";
import { useHeaderStore } from "../../store/HeaderStore";

const SettgModal = ({ closeModal }) => {
  const { editTgNickname } = useHeaderStore();
  const userData = useSessionStore().data;
  const getUserData = useSessionStore((session) => session?.getUserData);
  const [value, setValue] = useState(parseTelegramUsername(userData.contact) || "");

  const handleSave = useCallback(() => {
    editTgNickname(parseTelegramUsername(value)).then((res) => {
      if (res === "SUCCESS") {
        closeModal();
        setTimeout(() => {
          getUserData();
        }, 0);
      }
    });
  }, [closeModal, editTgNickname, getUserData, value]);

  return (
    <div className="modal-settg">
      <div className="settg-title">
        Set nickname TG
        <div className="window_close" onClick={closeModal}>
          <SvgClose />
        </div>
      </div>
      <div className="settg-input">
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          type="text"
          className="st-in"
        />
      </div>
      <div onClick={handleSave} className="p-btn">
        Save
      </div>
    </div>
  );
};

export default SettgModal;
