import { diff } from "deep-object-diff";
import { useCallback } from "react";

import { api } from "../../../shared/constants";
import { useConfigStore } from "./config-store";

export function useConfigSave() {
  const { config, userValues, userFormat, saveFormat, forceSaveList, getUserGroup } =
    useConfigStore();
  return useCallback(
    async ({ accIds, partId, selectedBotMode } = {}) => {
      try {
        if (!selectedBotMode) {
          return;
        }
        const initialUserValues = userFormat([
          ...(config?.[0] || []),
          ...(config?.[1] || []),
        ]);
        const difference = diff(initialUserValues, userValues);

        const forceSaveUserValues = forceSaveList?.reduce((acc, groupId) => {
          return {
            ...acc,
            ...(getUserGroup(groupId) || {}),
          };
        }, {});

        const saveValues = {
          ...forceSaveUserValues,
          ...difference,
        };

        // (0-MANUAL, 1-AUTO, 2-VIP)
        const workingMode = {
          0: "MANUAL",
          1: "AUTO",
          2: "VIP",
        }[selectedBotMode];

        if (Object.keys(saveValues)?.length) {
          return await api?.cfgSaveReq({
            accIds: accIds,
            partId: partId,
            cfgs: saveFormat(saveValues),
            workingMode,
          });
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    },
    [config, getUserGroup, forceSaveList, saveFormat, userFormat, userValues]
  );
}
