import { useMemo } from "react";

import { pokerApps } from "../../../shared/constants";
import { useRouteUtils } from "../../../shared/utils/use-route-utils";

export function useConfigRouteFilters() {
  const { searchParamsObject } = useRouteUtils();
  const { selectedRoom, selectedRoomType, selectedBotMode } =
    searchParamsObject || {};
  return useMemo(
    () => ({
      ...(selectedRoom && {
        selectedRoom: pokerApps.find((app) => app?.packageName === selectedRoom)
          ?.name,
      }),
      ...(selectedRoomType && {
        selectedRoomType,
      }),
      ...(selectedBotMode && {
        selectedBotMode,
      }),
    }),
    [selectedBotMode, selectedRoom, selectedRoomType]
  );
}
