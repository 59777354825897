import Cookies from "js-cookie";
import React, { useState } from "react";
import { Form } from "react-router-dom";

import { useSessionStore } from "../../entities/user/model/session-store";
import * as eventBus from "../../features/event-bus/use-event-bus";
import s from "./Login.scss";

function Login() {
  const darkModeCookie = Cookies.get("isDarkMode");

  const [agentCode, setAgentCode] = useState("");
  const { loginLoading, data } = useSessionStore();

  eventBus?.useResetGlobalState();

  return (
    <div className={`${s.loginPage} ${data ? s.hidden : ""}`}>
      <Form method="POST" className={s.container} replace>
        <input
          name="loginInput"
          placeholder="enter agent code"
          className={`${s.agentCodeInput} ${
            darkModeCookie === "1" ? s.darkInput : ""
          }`}
          id="loginPage_input"
          value={agentCode}
          onChange={(e) => setAgentCode(e.target.value)}
        />
        <button
          type="submit"
          className={`agent-code ${loginLoading ? s.loading : ""}`}
        >
          {!loginLoading && "Log in"}
        </button>
      </Form>
    </div>
  );
}

export default Login;
