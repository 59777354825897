import { useMemo } from "react";

import { useSessionStore } from "../../user/model/session-store";

export function useCurrentPartner() {
  const { currentUser: partId } = useSessionStore();
  return useMemo(
    () => ({
      partId,
    }),
    [partId]
  );
}
