import { useMemo } from "react";

export function useRoomSelectOptions(rooms) {
  return useMemo(() => {
    return (
      rooms &&
      Object.entries(rooms).map((item) => {
        return {
          value: item?.[0],
          label: item?.[0],
          icon: item?.[1]?.icon,
        };
      })
    );
  }, [rooms]);
}
