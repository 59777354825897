import { useQuery } from "@tanstack/react-query";

import { api } from "../../../shared/constants";

export function usePartnerRooms({ partId } = {}, queryOptions) {
  return useQuery({
    queryKey: [`/api/partners/getAllowedRoomsReq`, partId],
    queryFn: () => {
      return api
        ?.getAllowedRoomsReq({
          ...(partId && {
            partId,
          }),
        })
        ?.then((response) => response?.data?.allowedRooms);
    },
    ...queryOptions,
  });
}
