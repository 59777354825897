import { create } from "zustand";

import { api } from "../shared/constants";
import {
  parseAccountsForTable,
  parseJsonStringToArray,
} from "../shared/utils/utils";

export const useAccountsStore = create((set, get) => ({
  accounts: [],
  selectedAccounts: [],
  fetchAccounts: async (partId) => {
    try {
      const response = await api.accountsReq({ partId: partId });
      const parsedData = response.data;
      set({ accounts: parseAccountsForTable(parseJsonStringToArray(parsedData)) });
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  updateNote: async (accId, note) => {
    try {
      const response = await api.editNoteReq({ accId, notes: note });
      return response.data;
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  // ПЕРЕКЛЮЧАЕТ СОСТОЯНИЕ АККАУНТА
  switchAccountStatus: async (accId, status) => {
    try {
      const response = await api.enDisReq({ accId, enabled: status });
      return response.data;
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  handleToggleAccount: (accId) => {
    const selectedAccounts = [...(get().selectedAccounts || [])];
    const accountIndex = selectedAccounts.indexOf(accId);
    if (accountIndex === -1) {
      set((state) => ({ selectedAccounts: [...state.selectedAccounts, accId] }));
    } else {
      selectedAccounts.splice(accountIndex, 1);
      set({ selectedAccounts: selectedAccounts });
    }
  },
  updateRestartAccount: async (type) => {
    try {
      const response = await api.comReq({
        accIds: get().selectedAccounts,
        name: type,
        args: [],
      });
      const parsedData = response.data;
      if (parsedData.status === "SUCCESS") {
        set({ selectedAccounts: [] });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  deleteSelectedAccounts: async (accIds) => {
    try {
      const response = await api.deleteAccountsReq({ accIds: [...accIds] });
      return response.data;
    } catch (error) {
      console.error("Error: ", error);
    }
  },
  selectAllAccounts: () => {
    if (get().selectedAccounts.length === get().accounts.length) {
      set({ selectedAccounts: [] });
    } else {
      const selectedAccounts = get().accounts.map((item) => item.accId);
      set({ selectedAccounts: selectedAccounts });
    }
  },
  resetSelectedAccounts: () => set({ selectedAccounts: [] }),
  getAccounts: () => get().accounts,
  getSelectedAccounts: () => get().selectedAccounts,
}));
