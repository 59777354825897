import { useMemo } from "react";

import { useSessionStore } from "./model/session-store";

export function useCurrentUser() {
  const sessionStore = useSessionStore();
  return useMemo(
    () => ({
      partId: sessionStore?.data?.partId,
    }),
    [sessionStore?.data?.partId]
  );
}
