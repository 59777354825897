import { diff } from "deep-object-diff";
import { useMemo } from "react";

import { useConfigStore } from "../config-store";

export function useConfigDifference(configItem) {
  const { config, userValues, userFormat } = useConfigStore();

  const configs = useMemo(
    () => [...(config?.[0] || []), ...(config?.[1] || [])],
    [config]
  );

  const initialValues = useMemo(() => {
    return configs?.filter((item) => {
      return (
        !configItem ||
        item?.confId === configItem?.confId ||
        item?.parentConfId === configItem?.confId
      );
    });
  }, [configItem, configs]);

  const currentValues = useMemo(() => {
    return initialValues?.reduce((acc, item) => {
      return {
        ...acc,
        [item?.confId]: userValues?.[item?.confId],
      };
    }, {});
  }, [initialValues, userValues]);

  return useMemo(() => {
    return diff(userFormat(initialValues), currentValues);
  }, [currentValues, initialValues, userFormat]);
}
