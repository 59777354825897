const headerTranslations = {
  en: {
    accounts: "Accounts",
    sessions: "Sessions",
    partners: "Partners",
    fuelBalance: "Fuel Balance",
    accountConfiguration: "Configuration",
    deposit: "Deposit",
    usdtTrc20Amount: "USDT TRC20 amount",
    fuelAmount: "Fuel amount",
    add: "Add",
    nextGift: "for next gift",
    add100UsdtNextGift: "Add 100 usdt for next gift 5%",
    pay: "Pay",
    send: "Send",
    send10UsdtTrc20: "Send 10 USDT TRC20",
    copyAddress: "Copy address",
    scanFromQr: "or Scan from qr code",
    iPaid: "I paid",
    confirmations: "confirmations",
    transactionReceived: "Transaction received",
    history: "History",
    topUpHistory: "Top-up history",
    transferHistory: "Transfer History",
    date: "Date",
    usdt: "Usdt",
    fuel: "Fuel",
    gift: "Gift",
    partner: "Partner",
    dark: "Dark",
    cn: "CN",
    apk: "APK",
    setTg: "Set Tg",
    runGuide: "Run guide",
    logOut: "Log out",
    search: "SEARCH...",
    update: "Update",
    restart: "Restart",
    delete: "Delete",
    PARTNER_KEY_CHANGED: "Partner key has changed",
    DELETE_PARTID_ACCID: "Account has been deleted",
  },
  zh: {
    accounts: "账号",
    sessions: "期间",
    partners: "用户",
    fuelBalance: "燃料余额",
    accountConfiguration: "账户配置",
    deposit: "补充",
    usdtTrc20Amount: "USDT TRC20 数量",
    fuelAmount: "燃料量",
    add: "还加",
    nextGift: "送",
    add100UsdtNextGift: "还加100USDT 送5%",
    pay: "付款",
    send: "发",
    send10UsdtTrc20: "发10USDT TRC20",
    copyAddress: "复制地址",
    scanFromQr: "或者扫一扫",
    iPaid: "我已经付款了",
    confirmations: "确认",
    transactionReceived: "转账收了",
    history: "历史",
    topUpHistory: "补足历史",
    transferHistory: "转会历史",
    date: "日期",
    usdt: "USDT",
    fuel: "燃料",
    gift: "赠送",
    partner: "用户",
    dark: "暗",
    cn: "英文",
    apk: "apk",
    setTg: "设置Tg 账户",
    runGuide: "运行说明",
    logOut: "退出",
    search: "搜寻",
    update: "更新",
    restart: "重新启动",
    delete: "删除",
    PARTNER_KEY_CHANGED: "Partner key has changed",
    DELETE_PARTID_ACCID: "Account has been deleted",
  },
};

export default headerTranslations;
